import invoiceWithConcepts from './data/invoiceWithConcepts';
import invoiceWithOrders from './data/invoiceWithOrders';
import invoiceNoMatch from './data/invoiceNoMatch';

const responseDefault = {
  pagination: {
    next: null,
    previous: null,
    count: 1,
  },
  data: [
    {
      uuid: '769e603a-2786-4f15-a75b-929acde3e873',
      providerId: 'Lw-Mno-4Km_LBnwePcQa_g2',
      invoicingCode: '37A2201240170F',
      status: 'ACTIVE',
      invoiceType: 'GENERAL_INVOICE',
      createdDate: '21/01/2024',
      createdAt: '2024-01-22 04:42:54.797774+00:00',
      folio: 3,
      paymentMethod: null,
      amount: '176.00',
      currencyCode: 'MXN',
      xmlFile: 'http://django:8000/media/invoicing/xml_files/37A2201240170F.xml',
      pdfFile: 'http://django:8000/media/invoicing/pdf_files/37A2201240170F.pdf',
      cancellationXmlFile: null,
      issuerTaxId: 'EKU9003173C9',
      issuerFiscalName: 'ESCUELA KEMPER URGATE',
      issuerBrand: {
        uuid: '769e603a-2786-4f15-a75b-929acde3e873',
        name: 'Solo ventas Online',
      },
      taxFolio: '020b0064-f655-4b54-adad-edcf214b0881',
    },
    {
      uuid: '769e603a-2786-4f15-a75b-929acde3e872',
      providerId: 'Lw-Mno-4Km_LBnwePcQa_g2',
      invoicingCode: '37A2201240170F',
      status: 'ACTIVE',
      invoiceType: 'GENERAL_INVOICE',
      createdDate: '21/01/2024',
      createdAt: '2024-01-22 04:42:54.797774+00:00',
      folio: 5,
      paymentMethod: null,
      amount: '1200.00',
      currencyCode: 'MXN',
      xmlFile: 'http://django:8000/media/invoicing/xml_files/37A2201240170F.xml',
      pdfFile: 'http://django:8000/media/invoicing/pdf_files/37A2201240170F.pdf',
      cancellationXmlFile: null,
      issuerTaxId: 'EKU9003173C9',
      issuerFiscalName: 'ESCUELA KEMPER URGATE',
      issuerBrand: {
        uuid: '769e603a-2786-4f15-a75b-929acde3e872',
        name: 'Solo ventas Online',
      },
      taxFolio: '020b0064-f655-4b54-adad-edcf214b0882',
    },
    {
      uuid: '769e603a-2786-4f15-a75b-929acde3e871',
      providerId: 'Lw-Mno-4Km_LBnwePcQa_g2',
      invoicingCode: '37A2201240170F',
      status: 'ACTIVE',
      invoiceType: 'GENERAL_INVOICE',
      createdDate: '21/01/2024',
      createdAt: '2024-01-22 04:42:54.797774+00:00',
      folio: 5,
      paymentMethod: null,
      amount: '350.00',
      currencyCode: 'MXN',
      xmlFile: 'http://django:8000/media/invoicing/xml_files/37A2201240170F.xml',
      pdfFile: 'http://django:8000/media/invoicing/pdf_files/37A2201240170F.pdf',
      cancellationXmlFile: null,
      issuerTaxId: 'EKU9003173C9',
      issuerFiscalName: 'ESCUELA KEMPER URGATE',
      issuerBrand: {
        uuid: '769e603a-2786-4f15-a75b-929acde3e872',
        name: 'Solo ventas Online',
      },
      taxFolio: '020b0064-f655-4b54-adad-edcf214b0883',
    },
  ],
  datetime: '2024-01-22T16:16:37.894775Z',
};

export const InvoicePreCancelled = (req, res, ctx) => {
  const response = { ...responseDefault };

  return res(
    // Respond with a 200 status code
    ctx.status(200),
    ctx.json(response),
  );
};

export const InvoicePreCancelledDetail = (req, res, ctx) => {
  const { uuidInvoice } = req.params;
  const response = { ...responseDefault };

  if (uuidInvoice === '769e603a-2786-4f15-a75b-929acde3e873') {
    response.data = invoiceWithConcepts;
  } else if (uuidInvoice === '769e603a-2786-4f15-a75b-929acde3e871') {
    response.data = invoiceNoMatch;
  } else {
    response.data = invoiceWithOrders;
    response.datetime = new Date().toISOString();
  }
  return res(
    // Respond with a 200 status code
    ctx.status(200),
    ctx.json(response),
  );
};
