const responseSalesByUserType = {
  data: {
    uuid: 'f28f8de2-5cf5-4832-97ec-97e8e79bd0ad',
    storeName: 'Alejandro Store',
    sessionNumber: 293,
    state: 'CLOSED',
    startedAt: '15/01/24 14:19:41',
    finishedAt: '15/01/24 18:07:59',
    startedBy: 'Dayra Arrieta',
    finishedBy: 'Alex Salazar',
    ordersCounter: 2,
    contextDetail: {
      totalSales: '0.00',
      totalSalesByUser: [
        {
          userUuid: '32312323',
          name: 'Rolando Perez',
          value: '260.00',
          counter: 2,
        },
        {
          userUuid: '32312323',
          name: 'Socio Estella',
          value: '1000.00',
          counter: 10,
        },
      ],
    },
  },
  datetime: '2024-01-18T00:15:50.799158Z',
};

export default responseSalesByUserType;
