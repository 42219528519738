const responseDefault = {
  data: {
    uuid: '226ef000-fd0d-4388-b26d-98a3398343d9',
    categories: [],
    modifierGroups: [],
    menus: [],
    modifierOptions: [],
    kitchenAreasAssignation: [],
    isExempt: false,
    taxes: ['64543a3b-6b50-4aa7-917e-931f028d2509'],
    sku: 'AR-1683681918275',
    name: 'Test venta por peso 3',
    description: '',
    color: 'FFFFFF',
    image: null,
    legacyId: null,
    price: '1.00',
    alcoholCount: 0,
    soldAlone: true,
    availability: 'AVAILABLE',
    prepTime: 0,
    serving: '25.00',
    servingUnit: 'KILOGRAMS',
    organization: '69118ac6-0b1b-4604-8706-e16643135efc',
    category: null,
    posName: '',
    taxProductKey: '90101500',
    taxServingUnit: 'E48',
    createdAt: '2023-05-10T01:25:45.812925Z',
    updatedAt: '2023-05-10T01:25:45.812950Z',
  },
  datetime: '2023-05-10T01:29:26.190702Z',
};

export const getProductDetailWithGrammage = (req, res, ctx) => {
  responseDefault.datetime = new Date().toISOString();
  return res(ctx.status(200), ctx.json(responseDefault));
};
