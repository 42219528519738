/* eslint-disable no-plusplus */
// import { createUUID } from './utils';

const resposeDefault = {
  pagination: {
    next: null,
    previous: null,
    count: 3,
  },
  data: {
    summaries: {
      headers: [
        {
          name: 'Tipo de Pago',
          sorting: 1,
          tooltip: 'Los pagos realizados con Pay y otras terminales son montos brutos',
          alignment: 'left',
        },
        {
          name: 'Propina',
          sorting: 0,
          tooltip: null,
          alignment: 'right',
        },
        {
          name: 'Total sin propina',
          sorting: 0,
          tooltip: null,
          alignment: 'right',
        },
        {
          name: 'Total',
          sorting: 0,
          tooltip: null,
          alignment: 'right',
        },
      ],
      rows: [
        {
          uuid: '45865046-8c17-4006-9746-a7a4602907a3',
          content: [
            {
              value: 'Efectivo',
              symbol: null,
              alignment: 'left',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '0.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '2,850.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '2,850.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
          ],
        },
        {
          uuid: '45865046-8c17-4006-9746-a7a4602908a3',
          content: [
            {
              value: 'Transferencia',
              symbol: null,
              alignment: 'left',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '0.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '1,500.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '1,500.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'none',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
          ],
        },
        {
          uuid: '45865046-8c17-4006-9746-a7a4602908a8',
          content: [
            {
              value: 'TOTAL',
              symbol: null,
              alignment: 'left',
              styleText: 'bold',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '0.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'bold',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '4,350.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'bold',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
            {
              value: '4,350.00',
              symbol: '$',
              alignment: 'right',
              styleText: 'bold',
              hover: false,
              dataHover: null,
              symbolPosition: 'left',
            },
          ],
        },
      ],
    },
    labels: {
      title: 'Total Payments',
      subtitle: '$440,054.51',
    },
    headers: [
      {
        name: 'Fecha',
        sorting: 1,
      },
      {
        name: 'Número de referencia',
        sorting: 0,
      },
      {
        name: 'Usuario',
        sorting: 0,
      },
      {
        name: 'Marca',
        sorting: 0,
      },
      {
        name: 'Canal',
        sorting: 0,
      },
      {
        name: 'Tipo de pago',
        sorting: 0,
      },
      {
        name: 'Propina',
        sorting: 0,
      },
      {
        name: 'Total sin propina',
        sorting: 0,
      },
      {
        name: 'Total',
        sorting: 0,
      },
    ],
    rows: [
      {
        uuid: 'a48d0c24-eb52-43b9-8711-97e9e3c97ad',
        content: [
          {
            value: '05/10/2023 07:25 PM',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '051023-P-0008',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'Day Arrieta',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'La mejor marca de mexico y el mundo',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'Sucursal',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'Vales de despensa',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '80.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '400.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '480.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
        ],
      },
      {
        uuid: 'c8600c42-248f-4467-a6a7-a1423aaf55e9',
        content: [
          {
            value: '05/10/2023 07:22 PM',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '051023-P-0007',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'Day Arrieta',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'La mejor marca de mexico y el mundo',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'Sucursal',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 'Tarjeta mi nomina',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '50.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '750.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '800.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
        ],
      },
    ],
    datetime: '2023-10-05T19:00:26.714147Z',
  },
};

// function getRandomInt(max) {
//   return Math.floor(Math.random() * max);
// }

export const getPayments = (req, res, ctx) => {
  const oResponse = { ...resposeDefault };

  oResponse.datetime = new Date().toISOString();
  return res(ctx.status(200), ctx.json(oResponse));
};
