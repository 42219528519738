const invoiceNoMatch = {
  uuid: '769e603a-2786-4f15-a75b-929acde3e873',
  issuer: {
    invoicingConfig: 'not-found',
    brand: 'not-found',
    invoiceType: 'I',
  },
  receiver: {
    address: {
      country: 'México',
      extNum: '28',
      intNum: null,
      municipality: 'Ecatzingo',
      neighborhood: 'San José',
      state: 'México',
      street: 'Libertad',
      zipCode: '56990',
    },
    invoiceUse: 'G03',
    email: 'missael@parrotsoftware.io',
    fiscalName: 'MISSAEL ESTEBAN FLORES VERGARA',
    taxId: 'FOVM931226H35',
    fiscalRegime: '606',
  },
  paymentForm: '01',
  paymentMethod: 'PUE',
  concept: {
    orders: [],
    userDefined: [
      {
        uuid: '046690c4-6064-4754-ba52-c352375e2b06',
        items: [
          {
            uuid: '773520df-a152-472c-956c-92d577e14115',
            type: 'PRODUCT',
            quantity: 1,
            subTotal: 176,
            totalPrice: 176,
            description: 'Pizza Gringa',
            taxesApplied: [
              {
                tax: 'IVA',
                amount: 24.28,
                taxCode: 'IVA_16',
              },
            ],
            totalUnits: 1,
            servingUnit: 'PIECES',
          },
        ],
        isrRetention: 0,
      },
    ],
  },
  isResico: true,
};

export default invoiceNoMatch;
