const response = {
  pagination: {
    next: null,
    previous: null,
    count: 1,
  },
  data: [
    {
      uuid: '0e338191-a167-46f4-87d9-7a10f24dafbe',
      items: [
        {
          uuid: 'd34e8e62-fa65-4415-b39d-98ddf329e311',
          type: 'PRODUCT',
          quantity: 1,
          subTotal: '20.00',
          totalPrice: '20.00',
          description: 'product prueba',
          taxesApplied: [
            {
              tax: 'IVA',
              amount: '1.481481',
              taxCode: 'IVA_8',
            },
          ],
        },
        {
          uuid: 'fa034c6b-e5f8-47d5-8706-1f2edb71e965',
          type: 'PRODUCT',
          quantity: 1,
          subTotal: '20.00',
          totalPrice: '20.00',
          description: 'product prueba',
          taxesApplied: [
            {
              tax: 'IVA',
              amount: '1.481481',
              taxCode: 'IVA_8',
            },
          ],
        },
        {
          uuid: '8fd1ff5c-2fea-47ff-9f51-99e22aa4b274',
          type: 'PRODUCT',
          quantity: 1,
          subTotal: '20.00',
          totalPrice: '20.00',
          description: 'product prueba',
          taxesApplied: [
            {
              tax: 'IVA',
              amount: '1.481481',
              taxCode: 'IVA_8',
            },
          ],
        },
      ],
    },
  ],
  datetime: '2023-02-16T16:05:07.459386Z',
};

export default response;
