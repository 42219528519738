const response = {
  pagination: {
    next: null,
    previous: null,
    count: 9,
  },
  data: [
    {
      uuid: '71afe32c-9131-4426-9190-f892734b8621',
      name: 'Dueño',
      roleType: 'OWNER',
    },
    {
      uuid: '2b9fe72c-6541-48b0-83c6-9d5cbb3b19f8',
      name: 'Administrador',
      roleType: 'ADMIN',
    },
    {
      uuid: '3c7bd21e-91c7-4320-8e4b-67f3f255c12f',
      name: 'Gerente',
      roleType: 'MANAGER',
    },
    {
      uuid: '4d2be74c-b826-42b5-8977-019f36e35ed0',
      name: 'Cajero',
      roleType: 'CASHIER',
    },
    {
      uuid: '5e1ce85d-8726-4b6a-8b71-cfa6b8f2cde2',
      name: 'Mesero',
      roleType: 'WAITER',
    },
    {
      uuid: '6f4de95f-91d2-44b3-bd8b-89ec88d42f1d',
      name: 'Chef',
      roleType: 'CHEF',
    },
    {
      uuid: '7g5fe06b-839e-4c6a-b18f-22a9b1e68fcf',
      name: 'Cocina',
      roleType: 'KITCHEN',
    },
    {
      uuid: '8h6ge17a-a65b-4d59-8c1e-563d7d2f3c90',
      name: 'Colaboradores',
      roleType: 'STAFF',
    },
    {
      uuid: '9i7he28b-b76d-4e6a-9d4f-673e8e4c7d31',
      name: 'Sistemas',
      roleType: 'IT',
    },
    {
      uuid: '10j8if39-c87f-4f6b-9e5e-785f9f5d9d42',
      name: 'Sin definir',
      roleType: 'UNDEFINED',
    },
  ],
  datetime: '2024-01-22T16:16:37.894775Z',
};

export const getProfiles = (req, res, ctx) => {
  return res(
    // Respond with a 200 status code
    ctx.status(200),
    ctx.json({
      ...response,
      datetime: new Date().toISOString(),
    }),
  );
};
