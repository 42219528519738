import { RestRequest, ResponseFunction, RestContext } from 'msw';

const resposeDefault = {
  errors: [
    {
      code: 'parrot.ValidationError',
      message:
        'No puedes configurar el pago multisucursal por que cuentas con adeudo de licencia en alguna sucursal(es).',
    },
  ],
  validations: {},
};

export const updateGroupsPayments = (req: RestRequest, res: ResponseFunction, ctx: RestContext) => {
  const oResponse = { ...resposeDefault };

  return res(
    // Respond with a 400 status code
    ctx.status(400),
    ctx.json({
      ...oResponse,
    }),
  );
};
