const response = {
  data: [
    {
      uuid: 'e4421ac6-d94b-4bbf-a266-5b5198463c5d',
      items: [
        {
          uuid: '10fa45d9-52c6-4b30-8806-98513202ccc7',
          type: 'PRODUCT',
          quantity: 1.0,
          subTotal: 100.0,
          totalPrice: 100.0,
          description: 'Prueba excento',
          taxesApplied: [],
          totalUnits: 1.0,
          servingUnit: 'PIECES',
        },
        {
          uuid: 'f8da640d-0f3b-4643-bd00-8dc05d3d54aa',
          type: 'PRODUCT',
          quantity: 1.0,
          subTotal: 123.0,
          totalPrice: 123.0,
          description: 'Articulo IVA 0',
          taxesApplied: [
            {
              tax: 'IVA',
              amount: 0.0,
              taxCode: 'IVA_0',
            },
          ],
          totalUnits: 1.0,
          servingUnit: 'PIECES',
        },
        {
          uuid: 'b604cdfd-ab46-4395-8e03-a3eefffab62a',
          type: 'PRODUCT',
          quantity: 5.0,
          subTotal: 10.0,
          totalPrice: 50.0,
          description: 'Articulo IVA 16%',
          taxesApplied: [
            {
              tax: 'IVA',
              amount: 6.9,
              taxCode: 'IVA_16',
            },
          ],
          totalUnits: 5.0,
          servingUnit: 'PIECES',
        },
        {
          uuid: '67ee7bd6-47eb-4b32-9ace-d1e3b40152a8',
          type: 'PRODUCT',
          quantity: 1.0,
          subTotal: 65.0,
          totalPrice: 65.0,
          description: 'Articulo IEPS 8',
          taxesApplied: [
            {
              tax: 'IEPS',
              amount: 4.81,
              taxCode: 'IVA_0_IEPS_8',
            },
          ],
          totalUnits: 1.0,
          servingUnit: 'PIECES',
        },
      ],
      isrRetention: 4.08,
    },
  ],
  datetime: '2024-02-01T18:24:43.994610Z',
};

export default response;
