import { rest } from 'msw';

import { getPayments } from './payments';
import { getTipsSummary } from './tipsSummary';

const URL_BASE = `${process.env.REACT_APP_BASE_URL_API}/api/`;

export const reports = [
  rest.get(`${URL_BASE}v2/reports/payments/`, getPayments),
  rest.get(`${URL_BASE}v1/reports/cashier-session/:sessionUuid/`, getTipsSummary),
];
