import { rest } from 'msw';

import { InvoicePreCancelled, InvoicePreCancelledDetail } from './invoicePreCancelled';
import { GetOrderNumber } from './orderHandler';
import { TickerEditorIeps } from './ticketEditor';

const URL_BASE = `${process.env.REACT_APP_BASE_URL_API}`;

export const invoicing = [
  rest.get(`${URL_BASE}/api/v1/invoicing/generated-invoices/`, InvoicePreCancelled),
  rest.get(`${URL_BASE}/api/v1/invoicing/generated-invoices/:uuidInvoice/`, InvoicePreCancelledDetail),
  rest.get(`${URL_BASE}/api/v1/orders/:orderNumber/items/`, GetOrderNumber),
  rest.get(`${URL_BASE}/api/v2/invoicing/edited-orders/`, TickerEditorIeps),
];
