import { rest } from 'msw';

import { getInventoryLogBook } from './InventoryLogBook';
import { getReportCostAndMargins, getReportCostAndMarginsDetail } from './reportCostAndMargin';

const URL_BASE = `${process.env.REACT_APP_BASE_URL_API_INVENTORIES}/api/v1/`;

export const inventories = [
  rest.get(`${URL_BASE}supply-events/`, getInventoryLogBook),
  rest.get(`${URL_BASE}recipes/margin_report/`, getReportCostAndMargins),
  rest.get(`${URL_BASE}recipes/margin_report/:menuUuid/`, getReportCostAndMarginsDetail),
];
