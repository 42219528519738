const response = {
  errors: [
    {
      code: 'invoicing.OrderNotClosed',
      message: 'Esta orden no esta cerrada',
    },
  ],
  validation: null,
};

export default response;
