import response030124P0039 from './data/order030124-P-0039';
import resposeErrorOrderNotClose from './data/orderError199032-P-0004';
import responseDefaultOrder from './data/orderTest';

export const GetOrderNumber = (req, res, ctx) => {
  let defaultCode = 200;
  let response = responseDefaultOrder;
  const { orderNumber } = req.params;
  if (orderNumber === '030124-P-0039') response = response030124P0039;
  if (orderNumber === '199032-P-0004') {
    defaultCode = 400;
    response = resposeErrorOrderNotClose;
  }
  return res(
    // Respond with a 200 status code
    ctx.status(defaultCode),
    ctx.json(response),
  );
};
