import { rest } from 'msw';

import { getProductDetailWithGrammage } from './productWithGrammage';
import { getSummayDetailWithGrammage } from './summaryDetailMenuWithGammage';
import { getPubishError } from './publishError';

const URL_BASE = `${process.env.REACT_APP_BASE_URL_API}/api/v1/`;

export const menuMaker = [
  rest.get(`${URL_BASE}menu-maker/products/:productUuid/`, getProductDetailWithGrammage),
  rest.get(`${URL_BASE}menu-maker/menus/:menuUuid/detail/`, getSummayDetailWithGrammage),
  rest.post(`${URL_BASE}menu-maker/menus/publish/`, getPubishError),
];
