/* eslint-disable no-plusplus */
import { createUUID } from './utils';

const resposeDefault = {
  pagination: {
    next: null,
    previous: null,
    count: 1,
  },
  data: [],
  datetime: '',
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export const getReportCostAndMargins = (req, res, ctx) => {
  const page = req.url.searchParams.get('page');
  const size = req.url.searchParams.get('page_size');
  const oResponse = { ...resposeDefault };
  oResponse.pagination.next = Number(page) + 1;

  const data = [];
  for (let x = 0; x < Number(size); x++) {
    const oData = {
      menu: 'Menú sucursal Parrot Tacos',
      category: 'rndom',
      product: 'Tacos',
      option: 'Elige tu salsa: verde',
      price: getRandomInt(100),
      recipeCost: getRandomInt(100),
      lastRecipeCost: getRandomInt(100),
      teoricalProfit: -getRandomInt(100),
      realProfit: getRandomInt(100),
      teoricalMargin: getRandomInt(100),
      realMargin: -getRandomInt(100),
      recipeUUID: createUUID(),
      productUUID: createUUID(),
    };
    data.push(oData);
  }
  oResponse.data = data;
  oResponse.pagination.count = data.length;
  oResponse.datetime = new Date().toISOString();
  return res(ctx.status(200), ctx.json(oResponse));
};

export const getReportCostAndMarginsDetail = (req, res, ctx) => {
  const page = req.url.searchParams.get('page');
  const size = req.url.searchParams.get('page_size');
  const oResponse = { ...resposeDefault };
  oResponse.pagination.next = Number(page) + 1;

  const data = [];
  for (let x = 0; x < Number(size); x++) {
    const oData = {
      supplyName: 'Nombre supply',
      recipeUnit: 'KILOGRAM',
      recipeAmount: getRandomInt(100),
      teoricalCost: getRandomInt(100),
      lastCost: getRandomInt(100),
      lastPurchaseDate: '2021-07-08T01:33:06.791860',
    };
    data.push(oData);
  }

  const newData = {
    recipeName: 'Nombre de receta',
    productName: 'Nombre de producto',
    supplies: data,
  };

  oResponse.data = newData;
  oResponse.pagination.count = newData.length;
  oResponse.datetime = new Date().toISOString();
  return res(ctx.status(200), ctx.json(oResponse));
};
