import responseTipsSummaryDefault from './mockData/responseTipsSummaryDefault';
import responseSalesByPaymentType from './mockData/responseSalesByPaymentType';
import responseSalesByUserType from './mockData/responseSalesByUserType';

export const getTipsSummary = (req, res, ctx) => {
  const context = req.url.searchParams.get('context');
  let oResponse = {
    data: {},
    datetime: '',
  };
  if (context === 'TIP_TOTAL_SUMMARY') oResponse = { ...responseTipsSummaryDefault };
  if (context === 'SALES_BY_PAYMENT_TYPE') oResponse = { ...responseSalesByPaymentType };
  if (context === 'SALES_BY_USER') oResponse = { ...responseSalesByUserType };

  oResponse.datetime = new Date().toISOString();
  return res(ctx.status(200), ctx.json(oResponse));
};
