const responseDefault = {
  errors: [
    {
      code: 'menu.UploadError',
      message: 'Ocurrió un error al cargar el/los menu(s)',
    },
  ],
  validation: {
    DIDI_FOOD: [
      {
        type: 'PRODUCT',
        uuid: '0be5ca0e-a60c-4e67-b6d5-db672ec6adcd',
        name: 'Articulo que se vende por gramaje',
        errors: [
          {
            code: 'menu.ProductWithWeight',
            message:
              "El artículo 'Articulo que se vende por gramaje' en 'Menu Integración test Gramaje' se vende por peso y no puede ser publicado en un menú de integraciones",
          },
        ],
      },
    ],
    ONLINE: [
      {
        type: 'PRODUCT',
        uuid: '0c7a1969-921d-4657-94cb-8e6fce568c0e',
        name: 'Jamoncito',
        errors: [
          {
            code: 'menu.ProductWithWeight',
            message:
              "El artículo 'Jamoncito' en 'Menu emparedados frios' se vende por peso y no puede ser publicado en un menú de integraciones",
          },
        ],
      },
      {
        type: 'PRODUCT',
        uuid: '87236060-a126-4bb7-87e7-d8700c246874',
        name: 'Salmon',
        errors: [
          {
            code: 'menu.ProductWithWeight',
            message:
              "El artículo 'Salmon' en 'Menu emparedados frios' se vende por peso y no puede ser publicado en un menú de integraciones",
          },
        ],
      },
      {
        type: 'PRODUCT',
        uuid: '8815ccf7-5432-4b64-ab61-ff4d5538930e',
        name: 'Salmon chino',
        errors: [
          {
            code: 'menu.ProductWithWeight',
            message:
              "El artículo 'Salmon chino' en 'Menu emparedados frios' se vende por peso y no puede ser publicado en un menú de integraciones",
          },
        ],
      },
    ],
  },
};

export const getPubishError = (req, res, ctx) => {
  return res(ctx.status(400), ctx.json(responseDefault));
};
