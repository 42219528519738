const tickerEditorIepsResp = {
  pagination: {
    next: null,
    previous: null,
    count: 2,
  },
  data: {
    summaries: [
      {
        name: 'Ingresos totales',
        value: '5,006.81',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
      {
        name: 'Descuentos totales',
        value: '100.30',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
      {
        name: 'Impuestos',
        value: '672.83',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
      {
        name: 'Total por Facturar',
        value: '5,579.34',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
      {
        name: 'Ingresos totales (Act.)',
        value: '3,000.29',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
      {
        name: 'Descuentos totales (Act.)',
        value: '1,912.00',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
      {
        name: 'Impuestos (Act.)',
        value: '406.56',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
      {
        name: 'Total por facturar (Act.)',
        value: '3,667.34',
        symbol: '$',
        secondaryValue: null,
        secondarySymbol: null,
        sorting: 0,
      },
    ],
    labels: null,
    headers: [
      {
        name: 'Act.',
        sorting: 1,
      },
      {
        name: '# de recibo',
        sorting: 0,
      },
      {
        name: 'Artículos (Orig.)',
        sorting: 0,
      },
      {
        name: 'Artículos (Act.)',
        sorting: 0,
      },
      {
        name: 'Subtotal (Orig.)',
        sorting: 0,
      },
      {
        name: 'Subtotal (Act.)',
        sorting: 0,
      },
      {
        name: 'Descuento total (Orig.)',
        sorting: 0,
      },
      {
        name: 'Descuento total (Act.)',
        sorting: 0,
      },
      {
        name: 'IVA (Orig.)',
        sorting: 0,
      },
      {
        name: 'IVA (Act.)',
        sorting: 0,
      },
      {
        name: 'IEPS (Orig.)',
        sorting: 0,
      },
      {
        name: 'IEPS (Act.)',
        sorting: 0,
      },
      {
        name: 'Total (Orig.)',
        sorting: 0,
      },
      {
        name: 'Total (Act.)',
        sorting: 0,
      },
    ],
    rows: [
      {
        uuid: '10f07b9d-0f29-4ee1-a7eb-cc359d78ba33',
        content: [
          {
            value: 'Sí',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '10F1707248BA33',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 13.0,
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 8.0,
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '1,906.22',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'subtotal_original',
          },
          {
            value: '0.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'subtotal_updated',
          },
          {
            value: '100.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'discounts_original',
          },
          {
            value: '1,912.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'discounts_updated',
          },
          {
            value: '297.72',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'iva_original',
          },
          {
            value: '34.01',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'iva_updated',
          },
          {
            value: '2.56',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'ieps_original',
          },
          {
            value: '3.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'ieps_updated',
          },
          {
            value: '2,206.50',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'total_original',
          },
          {
            value: '294.50',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'total_updated',
          },
        ],
      },
      {
        uuid: '4ce16699-7699-4096-8455-391390ff598b',
        content: [
          {
            value: 'No',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '4CE110724F598B',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 23.0,
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 23.0,
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '1,493.71',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'subtotal_original',
          },
          {
            value: '1,493.71',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'subtotal_updated',
          },
          {
            value: '0.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'discounts_original',
          },
          {
            value: '0.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'discounts_updated',
          },
          {
            value: '147.43',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'iva_original',
          },
          {
            value: '147.43',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'iva_updated',
          },
          {
            value: '3.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'ieps_original',
          },
          {
            value: '3.50',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'ieps_updated',
          },
          {
            value: '1,641.14',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'total_original',
          },
          {
            value: '1,641.14',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'total_updated',
          },
        ],
      },
      {
        uuid: '6ffa734f-ae18-4424-a7a3-435678050aa6',
        content: [
          {
            value: 'No',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '6FF12042450AA6',
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 3.0,
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: 3.0,
            symbol: null,
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
          },
          {
            value: '88.07',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'subtotal_original',
          },
          {
            value: '88.07',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'subtotal_updated',
          },
          {
            value: '0.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'discounts_original',
          },
          {
            value: '0.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'discounts_updated',
          },
          {
            value: '11.93',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'iva_original',
          },
          {
            value: '11.93',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'iva_updated',
          },
          {
            value: '0.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'ieps_original',
          },
          {
            value: '0.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'ieps_updated',
          },
          {
            value: '100.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'total_original',
          },
          {
            value: '100.00',
            symbol: '$',
            alignment: 'left',
            styleText: 'none',
            hover: false,
            dataHover: null,
            symbolPosition: 'left',
            name: 'total_updated',
          },
        ],
      },
    ],
  },
  datetime: '2024-08-16T20:52:48.443256Z',
};

export default tickerEditorIepsResp;
