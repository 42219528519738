const invoiceWithOrders = {
  uuid: '769e603a-2786-4f15-a75b-929acde3e872',
  issuer: {
    invoicingConfig: '8c0a9302-a3eb-45bf-9696-426fa3786472',
    brand: '769e603a-2786-4f15-a75b-929acde3e873',
    invoiceType: 'I',
  },
  receiver: {
    address: {
      country: 'México',
      extNum: '28',
      intNum: null,
      municipality: 'Ecatzingo',
      neighborhood: 'San José',
      state: 'México',
      street: 'Libertad',
      zipCode: '56990',
    },
    invoiceUse: 'G03',
    email: 'missael@parrotsoftware.io',
    fiscalName: 'MISSAEL ESTEBAN FLORES VERGARA',
    taxId: 'FOVM931226H35',
    fiscalRegime: '606',
  },
  paymentForm: '01',
  paymentMethod: 'PUE',
  concept: {
    orders: [
      {
        uuid: '046690c4-6064-4754-ba52-c352375e2b06',
        orderReference: '0000-P-0000',
        items: [
          {
            uuid: '773520df-a152-472c-956c-92d577e14115',
            type: 'PRODUCT',
            quantity: 1.0,
            subTotal: 176.0,
            totalPrice: 176.0,
            description: 'Pizza Gringa',
            taxesApplied: [
              {
                tax: 'IVA',
                amount: 24.28,
                taxCode: 'IVA_16',
              },
            ],
            totalUnits: 1.0,
            servingUnit: 'PIECES',
            invoiceProductCode: '90101500',
            invoiceUnitCode: 'E48',
          },
        ],
        isrRetention: 0.0,
      },
    ],
    userDefined: [],
  },
  isResico: true,
};

export default invoiceWithOrders;
