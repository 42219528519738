const responseDefault = {
  data: [
    {
      uuid: 'dde00f3d-5f13-4e06-aa96-c325c961e973',
      name: 'Epic Burguer',
      products: [
        {
          uuid: '6ef0e096-4607-494b-9cff-82f3609b9b12',
          name: 'Coca Cola 355ml',
          price: '30.00',
          image: null,
          availability: 'AVAILABLE',
          modifierGroups: [],
          override: {
            uuid: '770d7921-d2a6-49dd-9af6-3403bb3245d0',
            price: 35.0,
            availability: 'AVAILABLE',
            modifiersOverride: false,
            image: null,
          },
          servingUnit: 'KILOGRAMS',
          serving: '25.00',
        },
        {
          uuid: 'c5bbec9c-eef0-4de0-83cb-0d5c2c6d192a',
          name: 'Hamburguesa doble receta',
          price: '150.00',
          image: null,
          availability: 'AVAILABLE',
          modifierGroups: [],
          override: {
            uuid: 'a7b29bfb-ca1c-4dc7-9e34-d064bd37ca0e',
            price: null,
            availability: 'AVAILABLE',
            modifiersOverride: false,
            image: null,
          },
        },
        {
          uuid: 'cc186e8e-1cc4-4d6b-ac5a-5dddf581c5fe',
          name: 'Combo Individual Dogo',
          price: '150.00',
          image: null,
          availability: 'AVAILABLE',
          modifierGroups: [
            {
              uuid: '887adc72-0477-4e4c-a291-2461f886feee',
              name: 'Elige tu Hot Dog',
            },
            {
              uuid: 'feeab0a1-d041-4292-9a9c-f3a009750323',
              name: 'Elige tu complemento',
            },
            {
              uuid: '1a2be6b2-e55e-4d93-aca8-0c9d7070e348',
              name: 'Elige tu bebida',
            },
          ],
          override: null,
        },
      ],
    },
  ],
  datetime: '2023-05-10T01:29:26.190702Z',
};

export const getSummayDetailWithGrammage = (req, res, ctx) => {
  responseDefault.datetime = new Date().toISOString();
  return res(ctx.status(200), ctx.json(responseDefault));
};
