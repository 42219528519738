/* eslint-disable no-plusplus */
import { createUUID } from './utils';

const resposeDefault = {
  pagination: {
    next: null,
    previous: null,
    count: 1,
  },
  data: [],
  datetime: '',
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const presentations = ['Caja', 'Bolsa Grande', 'Caja con 6 pza', 'Costalon'];
const presentationUnit = ['PZA', 'Lt', 'kg', 'ml'];

export const getInventoryLogBook = (req, res, ctx) => {
  const page = req.url.searchParams.get('page');
  const size = req.url.searchParams.get('page_size');

  const oResponse = { ...resposeDefault };
  oResponse.pagination.next = Number(page) + 1;

  const data = [];
  for (let x = 0; x < Number(size); x++) {
    const oData = {
      supplyCenterUUID: createUUID(),
      supplyUUID: createUUID(),
      supplyCenterName: `Centro de almacenamiento ${x}`,
      supplyName: `Insumo ${x}`,
      presentationName: presentations[getRandomInt(3)],
      initialCount: getRandomInt(100),
      inCount: getRandomInt(100),
      outCount: getRandomInt(100),
      consumptionCount: getRandomInt(100),
      transferCount: getRandomInt(100),
      adjustCount: getRandomInt(100),
      productionCount: getRandomInt(100),
      finalCount: getRandomInt(100),
      presentationUnit: presentationUnit[getRandomInt(3)],
    };
    data.push(oData);
  }
  oResponse.data = data;
  oResponse.pagination.count = data.length;
  oResponse.datetime = new Date().toISOString();
  return res(ctx.status(200), ctx.json(oResponse));
};
