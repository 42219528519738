const responseTipsSummaryDefault = {
  data: {
    uuid: '822b744a-f8f4-447d-bbef-36b53ad3da37',
    storeName: 'Alejandro Store',
    sessionNumber: 234,
    state: 'CLOSED',
    startedAt: '19/10/23 20:49:38',
    finishedAt: '19/10/23 20:50:59',
    startedBy: 'Alex Salazar',
    finishedBy: 'Alex Salazar',
    ordersCounter: 1,
    contextDetail: {
      store: [
        {
          name: 'BBVA',
          paymentType: 'DEBIT_CARD',
          style: null,
          total: '3.00',
          uuid: 'b0d1865b-8bf3-48de-b1e0-233a70cab907',
        },
        {
          name: 'SANTANDER',
          paymentType: 'DEBIT_CARD',
          style: null,
          total: '5.00',
          uuid: 'eba1a0de-036f-4c22-9baf-3b774c58c99a',
        },
        {
          name: 'Pay Terminal',
          paymentType: 'PAY',
          style: null,
          total: '20.00',
          uuid: null,
        },
        {
          name: 'Total Tips Amount',
          paymentType: null,
          style: {
            background: '#F2F2F4',
            fontWeight: 500,
          },
          total: '24.00',
          uuid: null,
        },
        {
          name: 'Total Payed Tips Amount',
          paymentType: null,
          style: null,
          total: '8.00',
          uuid: null,
        },
        {
          name: 'Total to Pay Tips Amount',
          paymentType: null,
          style: null,
          total: '16.00',
          uuid: null,
        },
        {
          name: '',
          paymentType: null,
          style: null,
          total: '',
          uuid: null,
        },
        {
          name: 'Retentions Amount',
          paymentType: null,
          style: {
            background: '#F2F2F4',
            fontWeight: 500,
          },
          total: '',
          uuid: null,
        },
      ],
      providers: [],
    },
  },
  datetime: '2023-10-23T22:18:42.630446Z',
};

export default responseTipsSummaryDefault;
