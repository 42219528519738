const responseSalesByPaymentType = {
  data: {
    uuid: 'f28f8de2-5cf5-4832-97ec-97e8e79bd0ad',
    storeName: 'Alejandro Store',
    sessionNumber: 293,
    state: 'CLOSED',
    startedAt: '15/01/24 14:19:41',
    finishedAt: '15/01/24 18:07:59',
    startedBy: 'Dayra Arrieta',
    finishedBy: 'Alex Salazar',
    ordersCounter: 2,
    contextDetail: {
      store: [
        {
          uuid: '2a487dce-38b9-4620-8e2b-3f8bf392846d',
          name: 'Cash',
          paymentType: 'CASH',
          totalSales: '0.00',
          totalCounter: 0,
          expectedAmount: '2000.00',
          reportedAmount: '2000.00',
          differenceAmount: '0.00',
        },
        {
          uuid: '7bcbe445-ccdf-4dbe-8378-0a95359192da',
          name: 'Pago con puntos',
          paymentType: 'THIRD_PARTY',
          totalSales: '0.00',
          totalCounter: 0,
          expectedAmount: '0.00',
          reportedAmount: '0.00',
          differenceAmount: '0.00',
        },
        {
          uuid: '8a1f0a7d-d904-48e4-aaec-af59f62a64b8',
          name: 'Credito HSBC',
          paymentType: 'CREDIT_CARD',
          totalSales: '0.00',
          totalCounter: 0,
          expectedAmount: '0.00',
          reportedAmount: '0.00',
          differenceAmount: '0.00',
        },
        {
          uuid: '5e0185f2-d446-4463-916d-10bee8b47015',
          name: 'Tarjeta debito BBVA',
          paymentType: 'DEBIT_CARD',
          totalSales: '0.00',
          totalCounter: 0,
          expectedAmount: '0.00',
          reportedAmount: '0.00',
          differenceAmount: '0.00',
        },
        {
          uuid: '1268dae8-12b8-4c78-b90b-3be548c452de',
          name: 'Vales de despensa',
          paymentType: 'THIRD_PARTY',
          totalSales: '0.00',
          totalCounter: 0,
          expectedAmount: '0.00',
          reportedAmount: '0.00',
          differenceAmount: '0.00',
        },
        {
          uuid: '489ebc4b-d288-4c3a-afc4-9fa0747e7ce3',
          name: 'Dolares',
          paymentType: 'FOREIGN_CURRENCY',
          totalSales: '150.00',
          currencyCode: 'MEX',
          totalCounter: 1,
          expectedAmount: '0.00',
          reportedAmount: '0.00',
          differenceAmount: '0.00',
          exchangeRate: '16.84',
          rate: {
            currencyCode: 'US',
            totalSales: '8.91',
            reportedAmount: '8.91',
            differenceAmount: '0',
          },
        },
      ],
      providers: {
        totalSales: '0.00',
        totalSalesByProviders: [
          {
            provider: 'UBER_EATS',
            name: 'Uber Eats',
            value: '0.00',
            counter: 0,
          },
          {
            provider: 'ONLINE',
            name: 'Pedidos Online',
            value: '0.00',
            counter: 0,
          },
        ],
      },
    },
  },
  datetime: '2024-01-18T00:15:50.799158Z',
};

export default responseSalesByPaymentType;
