// import { rest } from 'msw';

// import { ReportUser } from './ReportUser';
// import { getSalesMultiStore } from './controllers/reports/summary';
// import { getTranctions, getTransfers, getMovements } from './controllers/reports/transactions-pay';
// import { getSalesMultiStore } from './controllers/reports/summary';
// import { getValidationCopyMenu } from './controllers/menuMaker/menus';
// import { getSubscriptionPayments } from './controllers/subscription/subscriptionPayments';
import { inventories } from './inventories/inventoryHandler';
// import { PostUpdateConfig, GetAdminImagesPos } from './settings/adminImagesPos';
import { menuMaker } from './menuMaker/menuMakerHandler';
import { reports } from './reports/reportsController';
import { subscriptionController } from './controllers/subscription/subscriptionController';
// import { GetOrderNumber } from './invoicing/orderHandler';
// import { GetCustomerInvoice } from './invoicing/customerReportHandler';
// const URL_BASE = `${process.env.REACT_APP_BASE_URL_API}/api/`;
// const URL_BASE_PAY = `${process.env.REACT_APP_BASE_URL_API_PAY}/api/`;
import { invoicing } from './invoicing/invoicingHandler';
import { employees } from './employees/employeesHandler';

export const handlers = [
  // rest.get(`${URL_BASE}reports/users/`, ReportUser),
  // rest.get(`${URL_BASE}v1/reports/stores-sales/`, getSalesMultiStore),
  // rest.get(`${URL_BASE}v1/menu-maker/menus/:menuUuid/validate-copy/`, getValidationCopyMenu),
  // rest.get(`${URL_BASE_PAY}transactions/store/:storeId`, getTranctions),
  // rest.get(`${URL_BASE_PAY}transactions/grouped/store/:storeId`, getTransfers),
  // rest.get(`${URL_BASE_PAY}store/:storeId/movements`, getMovements),
  // rest.get(`${URL_BASE}v1/reports/stores-sales/`, getSalesMultiStore),
  // rest.get(`${URL_BASE}v1/menu-maker/menus/:menuUuid/validate-copy/`, getValidationCopyMenu),
  // rest.get(`${URL_BASE}v1/subscriptions/payments/`, getSubscriptionPayments),
  // rest.get(`${URL_BASE}v1/orders/:storeId/items/`, GetOrderNumber),
  // rest.get(`${URL_BASE}v1/invoicing/customer-invoices/`, GetCustomerInvoice),
  // rest.post(`${URL_BASE}v1/stores/settings/screen/:storeId`, PostUpdateConfig),
  // rest.get(`${URL_BASE}v1/stores/settings/screen/`, GetAdminImagesPos),
  ...inventories,
  ...menuMaker,
  ...reports,
  ...subscriptionController,
  ...invoicing,
  ...employees,
];
